import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export function createRouter() {
  return new Router({
    mode: 'history',
    routes: [
      // {
      //   path: '/dashboard',
      //   name: 'dashboard',
      //   component: () => import('./views/Dashboard.vue'),
			// 	props: {
			// 		setState: ''
			// 	}
      // },
      {
        path: '/',
        name: 'home',
        component: () => import('./views/Home.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('./views/About.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/agents',
        name: 'agents',
        component: () => import('./views/Agents.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/agents/:agentId',
        name: 'agent',
        component: () => import('./views/Agents.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/branches',
        name: 'branches',
        component: () => import('./views/Agents.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/branches/:branchId',
        name: 'branch',
        component: () => import('./views/Agents.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/portfolio',
        name: 'portfolio',
        component: () => import('./views/Portfolio.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/terms',
        name: 'terms',
        component: () => import('./views/Terms.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/disclaimer',
        name: 'disclaimer',
        component: () => import('./views/Disclaimer.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/article/:articleId',
        name: 'article',
        component: () => import('./views/Article.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/property/:propertyId',
        component: () => import('./views/Property.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/brochure/:propertyId',
        component: () => import('./views/Brochure.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/lifestyle',
        name: 'lifestyle',
        component: () => import('./views/Lifestyle.vue'),
				props: {
					setState: ''
				}
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('./views/Contact.vue'),
				props: {
					setState: ''
				}
      }
    ],
	  scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return { selector: to.hash };
      } else if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    }
  })
}
