import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export function createStore() {
	return new Vuex.Store({
		state: {
			version: 1,
			build: "https://chaslux.chasexchange.co.za",
			login: true,
			listings: null,
			navigationButtons: [],
			agentMails: [],
			search: {
				tags: {
					buy: false,
					rent: false,
					inland: false,
					coastal: false,
					countryside: false,
					city: false,
					commercial: false,
					min_price: 0,
					max_price: 0
				}
			}
		},
		mutations: {
			setFilter(state, { filter, value }) {
				Vue.set(state.search.tags, filter, value)
			},
			setMaxPrice(state, value) {
				state.search.maxPrice = value
			},
			setNavigationButtons(state, newArray) {
				state.navigationButtons = newArray;
			},
			setMaxPrice(state, value) {
				state.search.tags.max_price = value;
			},
			setMinPrice(state, value) {
				state.search.tags.min_price = value;
			},
		},
		actions: {
			updateFilter({ commit }, { filter, value }) {
				commit('setFilter', { filter, value })
			},
			updateMaxPrice({ commit }, value) {
				commit('setMaxPrice', value)
			},
		},
	})
}
